import React from 'react';

export const StatsModule = () => {
  return (
    <section>
      <div
        className="flex flex-col md:flex-row justify-center md:border border-b border-t border-ph-clover md:border-r-0 border-solid"
        style={{ marginTop: '64px' }}
      >
        {/* Left Stat  */}
        <div className="flex-1 border-l border-ph-clover px-4 md:mr-0 ml-8 mr-8 border-r border-solid">
          <div className="ph-font-euclid-regular pt-16 text-ph-forest font-medium text-center md:text-[96px] text-[48px]">
            85%
          </div>
          <p className="ph-font-euclid-regular px-11 pb-16 text-ph-forest text-center mt-4 text-lg md:text-[31px] text-[26px] ph-font-teodor-light">
            of members report improvement or elimination of symptoms.
          </p>
        </div>

        {/* Right Stat */}
        <div className="flex-1 px-4 md:mr-8 border-l md:border-l-0 border-r border-ph-clover mx-8 pr-11 md:pl-0 border-solid border-t md:border-t-0">
          <div className="ph-font-euclid-regular pt-16 text-ph-forest font-medium text-center md:text-[96px] text-[48px]">
            75%
          </div>
          <p className="px-11 pb-16 text-ph-forest text-center mt-4 text-lg md:text-[31px] text-[26px] ph-font-teodor-light">
            of members reduce their reliance on prescription medication within
            twelve months or less.
          </p>
        </div>
      </div>
    </section>
  );
};

export const centers = [
  {
    label: 'San Francisco',
    value: 'San Francisco',
    isClosed: true
  },
  {
    label: 'Los Angeles',
    value: 'West Hollywood'
  },
  {
    label: 'New York',
    value: 'New York'
  }
];

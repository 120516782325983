import React from 'react';
import { Layout, Module, ProviderGrid } from '../redesign';
import {
  HeroModule,
  HalfAndHalfNumberedListModule,
  SingleQuoteModule,
  StandardCarouselModule,
  ThirdPartyQuotesCarouselModule
} from '../redesign/page-builder';
import { useProviders } from '../hooks/use-providers';
import { InsuranceModule } from '../components/new-homepage/InsuranceModule';
import { StatsModule } from '../components/new-homepage/StatsModule';
import { PricingModule } from '../components/new-homepage/PricingModule';
import data from '../data/homepage.json';
import SEO from '../components/SEO';

export default function Homepage() {
  const {
    providers,
    centersOptions,
    providerOptions,
    setFilter
  } = useProviders();
  const components = data.components.items;

  return (
    <div className="homepage-new">
      <SEO
        pageDescription={`Parsley Health's leading medical providers use root-cause resolution medicine to treat health concerns where they start with personalized care, health coaching, and year-round, ongoing support. In-network with insurance plans in NY and CA.`}
        pageTitle={
          'Root-Cause Resolution Medicine | Functional Medicine | Parsley Health'
        }
        noindex={false}
      />
      <Layout theme="ivory">
        <HeroModule {...components[0]} />
        <StatsModule />
        <HalfAndHalfNumberedListModule {...components[10]} />
        <InsuranceModule />
        <Module theme="transparent">
          <ProviderGrid
            headline="Meet our world class clinicians:"
            providers={providers}
            filter={{
              locations: centersOptions,
              roles: providerOptions,
              onFilterChange: setFilter
            }}
            description="Our providers take the time needed to get a full picture of your health and create a care plan for long-term health. Plus, you can meet with them via secure video from wherever you are."
            ctaTrackingLabelMore="Show more button on Provider's page - provider grid"
            ctaTrackingLabelLess="Show less button on Provider's page - provider grid"
          />
        </Module>
        <StandardCarouselModule {...components[4]} />
        <SingleQuoteModule {...components[3]} />
        <PricingModule />
        <ThirdPartyQuotesCarouselModule {...components[9]} />
      </Layout>
    </div>
  );
}

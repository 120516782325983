import React from 'react';

export const InsuranceModule = () => {
  return (
    <section>
      <div className="bg-ph-gradient-mint to-[#eaecfa] p-8 md:p-16 text-center ph-font-euclid-regular">
        <h2 className="text-ph-forest font-euclid text-3xl md:text-4xl mb-4 text-[28px] font-semibold">
          Yes, we accept insurance.
        </h2>
        <p className="text-ph-forest font-euclid text-lg md:text-xl mb-8">
          Real healing through personalized care—now even more accessible.
        </p>
        <div className="flex flex-wrap justify-center gap-4 mb-16">
          {[
            'Aetna',
            'Anthem Blue Cross Blue Shield',
            'Cigna',
            'Oscar',
            'Blue Shield of California',
            'United Healthcare'
          ].map(insurance => (
            <span
              key={insurance}
              className="bg-ph-mint text-ph-forest px-4 py-2 rounded-lg font-euclid"
            >
              {insurance}
            </span>
          ))}
        </div>
        <a
          href="https://www.parsleyhealth.com/insurance/"
          className="inline-flex bg-ph-forest text-white px-8 py-4 rounded-full text-lg font-euclid hover:bg-opacity-90 transition duration-300"
        >
          Learn About Insurance
        </a>
        <p
          className="text-center font-euclid text-[14px] font-normal leading-[160%] mt-8"
          style={{
            color: 'var(--UI-Light-Text, #808080)',
            lineHeight: '22.4px'
          }}
        >
          If Parsley Health is in network with your health plan, insurance may
          help cover the cost of clinical appointments. It does not cover our
          program fee.
        </p>
      </div>
    </section>
  );
};
